//!SIGN IN PAGE FOR MOBILE
import React, {
  useState,
  useEffect,
  //  useCallback
} from "react";
import { COUNTRYCODE } from "../others/countryCodeList";
import { useNavigate } from "react-router-dom";
import { DialogContent, Dialog } from "@mui/material";
import { authentication } from "../firebaseAuth/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import Logo from "../Authservice/images/reliance-trends-logo-png.png";
// import token from "../Authservice/authToken";
import toast, { Toaster } from "react-hot-toast";

export default function MobileSignIn() {
  const [countryCode, setCountryCode] = useState("+91");
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(countryCode);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  // const UserContext = createContext();
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(Object.values(COUNTRYCODE.map((val)=>val)))
  const getPhoneNumber = countryCode + phoneNumber;

  // recatch function for authentication
  const reCaptchVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
        "expired-callback": () => {},
      },
      authentication
    );
  };

  // Verify user is exit or not in the database
  const [verifyData, setVerifyData] = useState(false);
  // const [verifyData2, setVerifyData2] = useState(false);
  // const url = "http://216.48.181.202:9889";
  // const url = "http://216.48.183.210:9889";
  let phoneNUmber2 = false;
  if (phoneNumber.length === 10) {
    phoneNUmber2 = phoneNumber;
  }
  // console.log("length of phone numnebr ",phoneNUmber2)
  const lambUrl = "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev";
  // const lambUrl = "https://18nowtq8u6.execute-api.ap-south-1.amazonaws.com/prod"

  useEffect(() => {
    async function getveriFiedUser() {
      try {
        const res = await axios.get(
          `${lambUrl}/user/verify_user_creds?numbers=${phoneNUmber2}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        // console.log(res.data);
        setVerifyData(res.data);
      } catch (error) {
        console.log("Error Calling all Store API: ", error);
      }
    }
    getveriFiedUser();
  }, [phoneNUmber2]);
  // console.log("verifyData2", verifyData)

  // useEffect(() => {
  //   async function getveriFiedUser() {
  //     try {
  //       const res = await axios.get(
  //         `${url}/data/users/verify_user_creds/${phoneNUmber2}`,
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: await token(),

  //             "content-type": { "Access-Control-Allow-Origin": "*" },
  //           },
  //         }
  //       );
  //       console.log(res.data);
  //       setVerifyData(res.data);
  //     } catch (error) {
  //       console.log("Error Calling all Store API: ", error);
  //     }
  //   }
  //   getveriFiedUser();
  // }, [phoneNUmber2]);
  // console.log("verifyData",verifyData)
  localStorage.setItem("users", JSON.stringify(verifyData));
  //  const UserProvider = ({ children }) => {
  //   const userDetails = verifyData

  //   return (
  //     <UserContext.Provider value={{userDetails}}>
  //       {children}
  //     </UserContext.Provider>
  //   );
  // };

  //RESEND OTP BUTTON TIMER FUNCTION
  const [mobTimer, setMobTimer] = useState(30);
  //  const mobTimeOutCallback = useCallback(
  //    () => setMobTimer((mobCurrTimer) => mobCurrTimer - 1),
  //    []
  //  );

  //  useEffect(() => {
  //    mobTimer >= 1 && setTimeout(mobTimeOutCallback, 1750);
  //  }, [mobTimer, mobTimeOutCallback]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (mobTimer > 0) {
        setMobTimer(mobTimer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [mobTimer]);

  const onSubmit = async (event) => {
    if (verifyData.data.user_role === "user") {
      toast.success("You are not authorized to access");
    } else {
      // console.log("start 1");
      reCaptchVerifier();
      // console.log("start 2");
      let appVerifier = window.recaptchaVerifier;

      // console.log("appVerifier", appVerifier);
      signInWithPhoneNumber(authentication, getPhoneNumber, appVerifier)
        .then((confirmationResult) => {
          // console.log("start processs");

          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
          // console.log("confirmationResult", confirmationResult);
          if (confirmationResult) {
            setOpen(true);
            setMobTimer(30);
            toast.success("OTP sent successfully");
          }
        })
        .catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
    }
  };

  // OTP verification
  const navigateTo = useNavigate();
  const [otp, setOtp] = useState();
  const [accessToken, setAccessToken] = useState("");
  const otpVerfication = async (event) => {
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;

        setAccessToken(user.accessToken);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log(error);
      });

    // console.log("confirmationResult", confirmationResult);
  };

  // const _currentUser = getFirebaseAuth().currentUser;

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(accessToken));
  }, [accessToken]);
  if (accessToken) {
    navigateTo("/dashboard");
  }

  //EDIT BUTTON REFRESH PAGE FUNCTION
  function refreshPage() {
    window.location.reload(false);
  }

  // console.log(timer);

  const resetTimer = function () {
    if (mobTimer === 0) {
      setMobTimer(30);
    }
    let appVerifier = window.recaptchaVerifier;
    // console.log("appVerifier", appVerifier);
    signInWithPhoneNumber(authentication, getPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        // console.log("start processs");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        toast.success("OTP resent successfully");
        // ...
        // console.log("confirmationResult", confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };
  return (
    <>
      <div className="flex justify-center md:hidden items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
        <div className="flex flex-col overflow-hidden py-10  bg-white rounded-md shadow-lg max md:flex-row md:flex-1 my-20 lg:max-w-screen-md">
          <div className="p-5 w-full bg-white md:flex-1">
            {/* <Image className="w-full m-auto  " src={Logo} width={150} height={70} alt="Img"/> */}
            <span
              id="rubikFont"
              className="my-4 text-2xl font-semibold text-gray-700"
            >
              Sign in
            </span>
            {/* <Formik initialValues={initialValues} onSubmit={onSubmit}> */}
            <div className="flex flex-col space-y-5">
              <div id="rubikFont" className="text-sm font-medium text-gray-500">
                to access Accounts
              </div>
              <div id="rubikFont" className="flex flex-row space-x-2">
                <select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="px-2 py-2 transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                >
                  {COUNTRYCODE.map((e) => (
                    <option value={e.dial_code}>{e.dial_code}</option>
                  ))}
                </select>
                <input
                  id="rubikFont"
                  type="text"
                  maxLength={10}
                  label="Number"
                  name="number"
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  placeholder="Mobile number"
                  autoFocus
                  className="px-4 py-2 w-full transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-emerald-300"
                />
              </div>

              <div>
                {verifyData.status === 201 ? (
                  <button
                    // onClick={loginAction}
                    type="submit"
                    className="w-full px-4 py-2 cursor-not-allowed text-lg font-semibold text-white transition-colors duration-300 bg-emerald-300 rounded-md shadow focus:outline-none focus:ring-emerald-200 focus:ring-4"
                  >
                    Verify user
                  </button>
                ) : (
                  <button
                    onClick={onSubmit}
                    // onClick={loginAction}
                    type="submit"
                    className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-emerald-500 rounded-md shadow hover:bg-emerald-600 active:bg-emerald-300 focus:outline-none focus:ring-blue-200 focus:ring-4"
                  >
                    Send OTP
                  </button>
                )}
                <Toaster />

                <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open}>
                  <DialogContent className=" relative">
                    <div
                      onClick={handleClose}
                      className="absolute right-2 top-2 cursor-pointer "
                    >
                      <RxCross2 className="text-2xl text-gray-400" />
                    </div>
                    <div className="w-full grid space-y-4 ">
                      <div id="rubikFont" className="flex flex-col space-y-3">
                        <span className="text-lg font-bold">
                          Please Enter OTP
                        </span>
                        <div className="flex flex-row space-x-2">
                          <div>
                            <span>OTP sent to </span>
                            <span>{phoneNumber}</span>
                          </div>
                          <button
                            type="button"
                            onClick={refreshPage}
                            className="text-emerald-600 hover:text-emerald-400"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col space-y-4">
                        <input
                          id="rubikFont"
                          label="OTP"
                          type="text"
                          maxLength={6}
                          minLength={6}
                          name="otp"
                          onChange={(event) => setOtp(event.target.value)}
                          className="px-4 py-2 transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-emerald-200"
                          placeholder="Enter 6 digit OTP"
                        />
                        {mobTimer === 0 ? (
                          <></>
                        ) : (
                          <>
                            <div className="w-full md:hidden">
                              Resend OTP in {mobTimer}
                            </div>
                          </>
                        )}

                        <div className="flex flex-row justify-evenly">
                          {mobTimer === 0 ? (
                            <>
                              <button
                                // onClick={otpVerfication}
                                onClick={resetTimer}
                                className="w-5/12 md:hidden px-4 py-2 text-base font-semibold text-emerald-600 transition-colors duration-300 border-2 border-emerald-500 rounded-md shadow hover:bg-gray-200 active:bg-emerald-200 active:text-white focus:outline-none focus:ring-emerald-200 focus:ring-4"
                              >
                                Resend
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                // onClick={otpVerfication}
                                className="w-5/12 md:hidden px-4 py-2 text-base font-semibold text-gray-400 transition-colors duration-300 border-2 border-gray-400 rounded-md shadow"
                              >
                                Resend
                              </button>
                            </>
                          )}
                          <button
                            onClick={otpVerfication}
                            className="w-5/12 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-emerald-500 rounded-md shadow hover:bg-emerald-600 active:bg-emerald-400 focus:outline-none focus:ring-emerald-200 focus:ring-4"
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      {/* <div className="grid space-y-2">
                        <input
                          label="OTP"
                          type="text"
                          maxLength={6}
                          minLength={6}
                          name="otp"
                          onChange={(event) => setOtp(event.target.value)}
                          className="px-4 py-2 transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-emerald-200"
                          placeholder="Enter 6 digit OTP"
                        />
                        <button
                          onClick={otpVerfication}
                          className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-emerald-500 rounded-md shadow hover:bg-emerald-600 active:bg-emerald-400 focus:outline-none focus:ring-emerald-200 focus:ring-4"
                        >
                          Submit
                        </button>
                      </div> */}
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              <div id="recaptcha-container"></div>
              {/* <span className="text-md text-blue-400 font-semibold hover:underline ml-32 focus:text-blue-800">
                Forgot Password?
              </span> */}
              <div className="grid grid-col space-y-5">
                <span className="flex items-center justify-center space-x-2">
                  <span className="h-px bg-gray-200 w-96"></span>
                </span>
              </div>
            </div>
            {/* </Formik> */}
          </div>
          <span className=" flex items-center justify-center space-x-2">
            <span className="h-full bg-gray-100 w-1"></span>
          </span>
          <div className="p-8 py-2 flex flex-col text-black bg-white-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
            {/* <img src="pwd.png " a lt="img"></img> */}
            <div className="self-center">
              <img
                className="w-58 h-12 scale-[2.5]"
                src={Logo}
                alt="Neo-DiShA Logo "
              />
            </div>
            {/* <div className="">
              {" "}
              <img className="w-58 h-12" src={Logo} alt="Neophyte Logo " />
            </div> */}

            <div className="mt-3 md:mt-0 text-center">
              {/* <span>DIShA</span> */}
              <p className="text-gray-600 pt-3 text-center text-lg">
                Image analysis platform for <br /> shelf-fullness monitoring and
                smarter retail execution
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
