import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Autocomplete from "@mui/material/Autocomplete";
import stateCities from "./stateCityData.json";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material";

// import { useFormik } from "formik";
// import * as Yup from "yup";
// import axios from "axios";
import token from "../Authservice/authToken";

// import { async } from "@firebase/util";
import {
  GetAllBrandApi,
  //  GetAllShelfApi,
  GETSHELVES,
} from "../Authservice/api";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";

// CREATING THE ARRAY OF THE ALL THE STATES IN INDIA
const states = Object.keys(stateCities);

// CREATING THE ARRAY FOR STORE TYPE
const storeType = ["Trends", "Smart", "Beauty"];

// const initialValues = {
//   name: "",
//   number: "",
//   location: "",
//   brands: "",
//   shelves: "",
// };

// const url = "http://216.48.183.210:9889";
// const url = "http://216.48.181.202:9889";
// const url = "http://localhost:8080";
const lambUrl = "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev";
// const validationSchema = Yup.object({
//   name: Yup.string().required("Store Name is Required"),
//   number: Yup.string().required("Store Number is Required"),
//   location: Yup.string().required("Location is Required"),
//   // brands: Yup.string().required("Brand is Required"),
//   // shelves: Yup.string().required("Shelf is required"),
// });

export default function AddStore() {
  const [brand, setBrand] = useState(false);
  const [shelf, setShelf] = useState(false);
  const [toast, setToast] = useState({ isToast: false, message: "", type: "" });
  const [shelvesValue, setShelvesValue] = useState([]);
  const [brandValue, setBrandValue] = useState([]);
  const [storeInfo, setStoreInfo] = useState({ id: 0, name: "", type: "" });
  const [location, setLocation] = useState({
    area: "",
    region: "",
    state: "",
    city: "",
    // pinCode: 0,
  });
  // const [
  //   // number,
  //   setNumber,
  // ] = useState("");

  // const [geography, setGeography] = useState({ latitude: "", longitude: "" });
  // const onSubmit = async (values) => {
  //   // console.log("🚀 ~ file: newStore.js ~ line 25 ~ onSubmit ~ ̥", values);
  //   const storeDetails = {
  //     name: values.name,
  //     number: values.number,
  //     location: values.location,
  //     brands: values.brands,
  //     shelves: values.shelves,
  //   };
  //   // console.log("storeDetails", storeDetails);
  //   // try {
  //   //   const data = await axios.post(`${url}/data/store/create`, storeDetails, {
  //   //     headers: {
  //   //       Accept: "application/json",
  //   //       Authorization: await token(),
  //   //     },
  //   //   });
  //   //   // console.log(data);
  //   //   if (data) {
  //   //     window.location.reload();
  //   //   }
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };
  // console.log("shelvesValue",shelvesValue )
  useEffect(() => {
    async function getStores() {
      try {
        const data = await GetAllBrandApi();
        // console.log(data.data);
        setBrand(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
  }, []);

  // console.log("branbd", brand);

  useEffect(() => {
    async function getShelf() {
      try {
        const data = await GETSHELVES();
        // console.log(data.data);
        setShelf(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getShelf();
  }, []);
  const shelfCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  // console.log("brand", shelf);

  const [counter, setCounter] = useState(0);

  const handleClick = () => {
    setCounter(counter + 1);
    // console.log(counter);
  };

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToastClose = () => {
    setToast({ isToast: false, message: "", type: "" });
  };
  const handleSubmit = async () => {
    // {

    //   "store_id":"TTX992",
    //   "name": "Kharghar",
    //   "type":"TRENDS",
    //   "location":"viswakarma tower , kharghar, Navi Mumbai",
    //   "region":"Mumbai",
    //   "state": "Maharastra",
    //   "dist": "Mumbai",
    //   "pincode": 742077,
    //   "location_ip": ["1222333","922929929"],

    //   "brands": ["62e4e72023787b6f3d089996", "62cd7d646544305395bb1f09"],
    //   "shelves": [3, 1]
    // }
    const storeDetails = {
      store_id: storeInfo.id,
      name: storeInfo.name,
      type: storeInfo.type,
      location: location.area,
      region: location.region,
      state: location.state,
      dist: location.city,
      // pincode: location.pinCode,
      // lat: geography.latitude,
      // lag: geography.longitude,
      brands: brandValue,
      shelves: shelvesValue,
    };
    try {
      // https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev/stores/create
      const data = await axios.post(`${lambUrl}/stores/create`, storeDetails, {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      });
      // console.log(data);
      if (data) {
        window.location.reload();
      }
    } catch (error) {
      setToast({
        isToast: true,
        message: error.response.data,
        type: "warning",
      });
      console.log(error.response.data);
    }
    // console.log(storeDetails);
  };
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            fontSize: "20px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            "&.MuiInputLabel-shrink": {
              fontSize: "20px",
            },
          },
        },
      },
    },
  });

  return (
    <>
      {userData.data.user_role === "Super Admin" ||
      userData.data.user_role === "NHQ" ? (
        <Button
          id="rubikFont"
          variant="contained"
          sx={{
            backgroundColor: "#10b981",
          }}
          onClick={handleClickOpen}
          //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
        >
          Add a store
        </Button>
      ) : (
        <></>
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent className="overflow-y-auto scrollbar">
          <div className="w-full font-[Roboto]">
            <div className="w-full flex justify-between text-xl mb-5 text-gray-600 font-bold">
              <span className="self-center">Add New Store</span>
              <IconButton
                edge="end"
                onClick={handleClose}
                aria-label="close"
                className="self-center"
              >
                <CloseIcon />
              </IconButton>
            </div>
            {/* <form onSubmit={formik.handleSubmit} action=""> */}

            <div className="w-full space-y-6">
              <div className="flex justify-start items-center gap-2">
                <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                  1
                </p>
                <h1 className="text-lg">Store Info</h1>
              </div>
              <div className="w-full flex bprder sm:space-x-2 flex-wrap sm:flex-nowrap">
                <div className="sm:w-1/2 w-full sm:mb-0 mb-2">
                  <ThemeProvider theme={theme}>
                    <TextField
                      className="w-full"
                      type="text"
                      label="Store ID"
                      variant="outlined"
                      name="number"
                      onChange={(e) =>
                        setStoreInfo({ ...storeInfo, id: e.target.value })
                      }
                    />
                  </ThemeProvider>
                </div>
                <div className="w-full sm:mb-0 mb-2">
                  <ThemeProvider theme={theme}>
                    <TextField
                      type="text"
                      className="w-full"
                      label="Store Name"
                      variant="outlined"
                      name="name"
                      onChange={(e) =>
                        setStoreInfo({ ...storeInfo, name: e.target.value })
                      }
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      // value={formik.values.name}
                    />
                  </ThemeProvider>
                  {/* {formik.touched.name && formik.errors.name ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.name}
                  </div>
                ) : null} */}
                </div>

                <div className="sm:w-1/2 w-full">
                  <ThemeProvider theme={theme}>
                    <FormControl fullWidth>
                      <InputLabel>Store Type</InputLabel>
                      <Select
                        label="Store Type"
                        name="type"
                        value={storeInfo.type}
                        onChange={(e) => {
                          setStoreInfo({ ...storeInfo, type: e.target.value });
                          console.log(e.target.value);
                        }}
                      >
                        {storeType.map((type, index) => {
                          return (
                            <MenuItem value={type} key={index}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </div>
              </div>
              <hr />
              <div className="flex justify-start items-center gap-2">
                <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                  2
                </p>
                <h1 className="text-lg">Store Location</h1>
              </div>

              <div className="w-full flex bprder gap-2 flex-wrap">
                <div className="flex w-full gap-2 sm:flex-nowrap flex-wrap">
                  <div className="w-full mb-0.5 sm:mb-0">
                    <ThemeProvider theme={theme}>
                      <TextField
                        type="text"
                        className="w-full"
                        label="Area"
                        variant="outlined"
                        name="area"
                        onChange={(e) =>
                          setLocation({ ...location, area: e.target.value })
                        }
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.name}
                      />
                    </ThemeProvider>
                    {/* {formik.touched.name && formik.errors.name ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.name}
                  </div>
                ) : null} */}
                  </div>
                  <div className="w-full mb-0.5 sm:mb-0">
                    <ThemeProvider theme={theme}>
                      <TextField
                        type="text"
                        className="w-full"
                        label="Region"
                        variant="outlined"
                        name="region"
                        onChange={(e) =>
                          setLocation({ ...location, region: e.target.value })
                        }
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.name}
                      />
                    </ThemeProvider>
                    {/* {formik.touched.name && formik.errors.name ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.name}
                  </div>
                ) : null} */}
                  </div>
                </div>
                <div className="w-full flex gap-0.5 sm:gap-2 flex-wrap sm:flex-nowrap">
                  <div className="w-full mb-2 sm:mb-0">
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        options={states.sort()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Your State"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, value) => {
                          setLocation({ ...location, state: value });
                        }}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="w-full mb-2 sm:mb-0">
                    {location.state ? (
                      <ThemeProvider theme={theme}>
                        <Autocomplete
                          options={stateCities[location.state].sort()}
                          noOptionsText="No locations"
                          disableListWrap
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Your City"
                              variant="outlined"
                            />
                          )}
                          onChange={(event, value) => {
                            setLocation({ ...location, city: value });
                          }}
                        />
                      </ThemeProvider>
                    ) : (
                      <TextField
                        label="First Select State"
                        variant="outlined"
                        className="w-full"
                        disabled
                      />
                    )}
                  </div>
                  {/* <div className="w-full">
                    <TextField
                      className="w-full"
                      type="number"
                      label="PIN Code"
                      variant="outlined"
                      name="pin"
                      onChange={(e) =>
                        setLocation({ ...location, pinCode: e.target.value })
                      }
                    />
                  </div> */}
                </div>
              </div>
              {/* <div className="w-full">
            <TextField
              className="w-full"
              label="Area"
              variant="outlined"
              name="location"
              onChange={(e) => setLocation(e.target.value)}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.location}
            />
            // {formik.touched.location && formik.errors.location ? (
            //     <div className=" ml-2 text-sm text-red-600">
            //       {formik.errors.location}  
            //     </div>
            //   ) : null}
          </div> */}
              <hr />
              {/* <div className="flex justify-start items-center gap-2">
                <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                  3
                </p>
                <h1 className="text-lg">Geography</h1>
              </div>
              <div className="w-full flex bprder sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2">
                <div className="w-full">
                  <TextField
                    className="w-full"
                    type="text"
                    label="Latitude"
                    variant="outlined"
                    name="latitude"
                    onChange={(e) =>
                      setGeography({ ...geography, latitude: e.target.value })
                    }
                  />
                </div>
                <div className="w-full">
                  <TextField
                    className="w-full"
                    type="text"
                    label="Longitude"
                    variant="outlined"
                    name="lognitude"
                    onChange={(e) =>
                      setGeography({ ...geography, longitude: e.target.value })
                    }
                  />
                </div>
              </div>
              <hr /> */}
              <div className="flex justify-start items-center gap-2">
                <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                  4
                </p>
                <h1 className="text-lg">Brand & Shelves</h1>
              </div>
              <div className="w-full flex space-x-4">
                <div className=" w-full flex space-x-2">
                  <div className="w-full">
                    <ThemeProvider theme={theme}>
                      <FormControl fullWidth>
                        <InputLabel>Brand</InputLabel>
                        <Select
                          label="Brands"
                          name="brands"
                          // onChange={(e) => setBrandValue(e.target.value)}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setBrandValue((prevSelectedBrands) => {
                              if (prevSelectedBrands.includes(selectedValue)) {
                                return prevSelectedBrands.filter(
                                  (value) => value !== selectedValue
                                );
                              } else {
                                return [...prevSelectedBrands, selectedValue];
                              }
                            });
                          }}
                        >
                          {brand &&
                            brand.map((val) => (
                              <MenuItem value={val.id}>{val.name}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </ThemeProvider>
                  </div>
                </div>
                <div className="w-full flex space-x-2">
                  <div className="w-full">
                    {shelf && (
                      <ThemeProvider theme={theme}>
                        <FormControl fullWidth>
                          <InputLabel>Shelves</InputLabel>
                          <Select
                            label="Shelves"
                            name="shelves"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setShelvesValue((prevSelectedBrands) => {
                                if (
                                  prevSelectedBrands.includes(selectedValue)
                                ) {
                                  return prevSelectedBrands.filter(
                                    (value) => value !== selectedValue
                                  );
                                } else {
                                  return [...prevSelectedBrands, selectedValue];
                                }
                              });
                            }}
                          >
                            {shelfCount &&
                              shelfCount.map((val) => (
                                <MenuItem value={val}>{val}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </ThemeProvider>
                    )}
                  </div>

                  <div className="m-auto">
                    <Button
                      sx={{
                        backgroundColor: "#059669",
                      }}
                      onClick={handleClick}
                      variant="contained"
                    >
                      <ControlPointIcon />
                    </Button>
                  </div>
                </div>
              </div>
              {Array.from(Array(counter)).map((c, index) => {
                return (
                  <div key={index} className="w-full flex  space-x-4">
                    <div className=" w-full flex space-x-2">
                      <div className="w-full">
                        <ThemeProvider theme={theme}>
                          <FormControl fullWidth>
                            <InputLabel>Brand</InputLabel>
                            <Select
                              label="Brands"
                              name="brands"
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                setBrandValue((prevSelectedBrands) => {
                                  if (
                                    prevSelectedBrands.includes(selectedValue)
                                  ) {
                                    return prevSelectedBrands.filter(
                                      (value) => value !== selectedValue
                                    );
                                  } else {
                                    return [
                                      ...prevSelectedBrands,
                                      selectedValue,
                                    ];
                                  }
                                });
                              }}
                            >
                              {brand &&
                                brand.map((val) => (
                                  <MenuItem value={val.id}>{val.name}</MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>

                        {/* {formik.touched.brands && formik.errors.brands ? (
                        <div className=" ml-2 text-sm text-red-600">
                          {formik.errors.brands}
                        </div>
                      ) : null} */}
                      </div>
                    </div>
                    <div className="w-full flex space-x-2">
                      <div className="w-full">
                        {shelf && (
                          <ThemeProvider theme={theme}>
                            <FormControl fullWidth>
                              <InputLabel>Shelves</InputLabel>
                              <Select
                                label="Shelves"
                                name="shelves"
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  setShelvesValue((prevSelectedBrands) => {
                                    if (
                                      prevSelectedBrands.includes(selectedValue)
                                    ) {
                                      return prevSelectedBrands.filter(
                                        (value) => value !== selectedValue
                                      );
                                    } else {
                                      return [
                                        ...prevSelectedBrands,
                                        selectedValue,
                                      ];
                                    }
                                  });
                                }}
                              >
                                {shelfCount &&
                                  shelfCount.map((val) => (
                                    <MenuItem value={val}>{val}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </ThemeProvider>
                        )}
                        {/* {formik.touched.shelves && formik.errors.shelves ? (
                        <div className=" ml-2 text-sm text-red-600">
                          {formik.errors.shelves}
                        </div>
                      ) : null} */}
                      </div>
                      {/* <TextField
                  className="w-full"
                  label="Shelves"
                  name="shelves"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shelves}
                /> */}
                    </div>
                  </div>
                );
              })}
              <div className="flex justify-end w-full gap-2">
                <button
                  onClick={handleClose}
                  className="rounded-md shadow-md text-sm font-bold bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-20 h-8"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  onClick={handleSubmit}
                  className=" rounded-md shadow-md text-sm font-bold bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-20 h-8"
                >
                  Save
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
          {toast.isToast && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleToastClose}
            >
              <Alert
                onClose={handleToastClose}
                severity={toast.type}
                sx={{ width: "100%" }}
              >
                {toast.message}
              </Alert>
            </Snackbar>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
