import React, { useState, useEffect } from "react";
// import { FaEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {
  GetALLStoreAPI,
  UPDATE_USER,
  // GETUSERDETAILS
} from "../Authservice/api";
import token from "../Authservice/authToken";
import { createTheme, ThemeProvider } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

// import { Autocomplete } from "@mui/material";
// import { AiOutlineSync } from "react-icons/ai";

// import DetailsContent from "./detailsContent";

export default function EditUser(props) {
  const data = props.setValue;
  // console.log("LapDeskEditsetValue", data);
  // const url = "http://localhost:8080";
  const url = "http://216.48.183.210:9889";
  // const url = "http://216.48.181.202:9889";

  const [store, setStore] = useState(false);

  const options = ["Active", "Inactive"];

  // const [personName, setPersonName] = useState([]);

  // const userType = ["Agent", "Manager", "Cluster Manager", "Super User"];

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  // const [close, setClose] = useState(false);

  //  Popup Edit User button

  // const handleUpdateOpen = () => {
  //   setClose(true);
  // };

  //   const handleUpdateClose = () => {
  //     setClose(false);
  //   };
  const [detailsUser, setDetailsUser] = useState(false);

  const lambUrl = "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev";
  // const lambUrl =
  //   "https://18nowtq8u6.execute-api.ap-south-1.amazonaws.com/prod";

  // const lambUrl = "https://18nowtq8u6.execute-api.ap-south-1.amazonaws.com/prod";

  //  const localUrl = "http://localhost:8080"

  // const [selectUser, setSelectUser] = useState(false);

  //  Popup Edit User button
  const [edit, setEdit] = useState(false);

  const handleEditOpen = () => {
    setEdit(true);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");

  //   get all store api
  useEffect(() => {
    async function getUser() {
      try {
        const data = await GetALLStoreAPI();
        // console.log(data.data);
        setStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    async function getDetails() {
      try {
        const res = await axios.get(
          `${lambUrl}/user/get_user_details?userId=${data}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: await token(),
            },
          }
        );
        setDetailsUser(res.data);
        return res;
      } catch (error) {
        console.log("Error Calling all Store API: ", error);
      }
    }
    getDetails();
  }, [data]);
  // console.log("store", store)
  if (store && detailsUser) {
    const storeFilter = store.filter((val) => {
      return val.id === detailsUser.store;
    });

    if (storeFilter[0].store_id === undefined) {
      detailsUser.store_number = "000";
    } else {
      detailsUser.store_number = storeFilter[0].store_id;
    }
  }

 

  // formik for edit user
  const initialValues = {
    user_name: "",
    user_id: "",
    email: "",
    number: "",
    user_role: "",
    store: "",
  };

  const onSubmit = async (values) => {
    // console.log(values);
    const userDetails = {
      user_name: values.user_name,
      user_id: values.user_name,
      email: values.email,
      number: values.number,
      user_role: values.user_role,
      store: values.store,
    };

    try {
      const response = await axios.post(
        `${url}/data/user/update/details/${data}`,
        userDetails,
        {
          headers: {
            Accept: "application/json",
            Authorization: await token(),
          },
        }
      );
      // console.log("UPdate User details", response);
      if (response) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("user details", detailsUser);

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const handleSubmit = async () => {
    if(detailsUser.user_role === "Agent"){
      detailsUser.user_role = "user"
    }else if(detailsUser.user_role === "Super User"){
      detailsUser.user_role = "NHQ"
    }
    // console.log("detailsUser", detailsUser)
   
    const final_data = {
      id: detailsUser._id,
      body: detailsUser,
    };
    try {
      const data = await UPDATE_USER(final_data);
      if(data){
        toast.success("User Updated Successfully");
        // setEdit(false)
      } else {
        toast.error("User Update Failled");
      }
    } catch (error) {
      console.log("update user API error", error);
    }
  };
  // console.log("detailsUser", detailsUser);
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            fontSize: "20px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            "&.MuiInputLabel-shrink": {
              fontSize: "20px",
            },
          },
        },
      },
    },
  });
  // console.log("detail User", detailsUser)
  
  return (
    <>
      {/*EDIT VERSION FOR LAPTOP & DESKTOP VERSION*/}
      <div
        onClick={() => {
          handleEditOpen();
          //   setSelectUser(val._id);
        }}
        className="cursor-pointer w-fit"
      >
        {userData.data.user_role === "Super Admin" ||
        userData.data.user_role === "NHQ" ? (
          <>
            <MdEdit className="text-emerald-600" />
          </>
        ) : (
          <>
            <BsFillEyeFill className="text-emerald-600" />
          </>
        )}
        {/* <FaEdit className="hidden xl:block" /> */}
      </div>
      <Dialog
        open={edit}
        onClose={handleEditClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent className="overflow-y-auto scrollbar">
          <div className="w-full font-[roboto]">
            <div className="w-full flex justify-between text-xl mb-5 text-gray-600 font-bold">
              <span className="self-center">User Details</span>
              <IconButton
                edge="end"
                onClick={handleEditClose}
                aria-label="close"
                className="self-center"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {detailsUser && <></>}
          <div className="w-full flex sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2 pb-4">
            <div className="w-full">
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  label="User ID"
                  name="user_id"
                  variant="outlined"
                  InputProps={
                    userData.data.user_role === "Super Admin" ||
                    userData.data.user_role === "NHQ"
                      ? { readOnly: false }
                      : { readOnly: true }
                  }
                  //   defaultValue={states[0]}
                  value={detailsUser.user_id}
                  onChange={(e) => {
                    setDetailsUser({ ...detailsUser, user_id: e.target.value });
                  }}
                  onBlur={formik.handleBlur}
                  // value={formik.values.user_id}
                />
              </ThemeProvider>

              {formik.touched.user_id && formik.errors.user_id ? (
                <div className="ml-2 text-sm text-red-600">
                  {formik.errors.user_id}
                </div>
              ) : null}
            </div>
            <div className="w-full">
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  label="User Name"
                  name="user_name"
                  variant="outlined"
                  InputProps={
                    userData.data.user_role === "Super Admin" ||
                    userData.data.user_role === "NHQ"
                      ? { readOnly: false }
                      : { readOnly: true }
                  }
                  //   defaultValue={states[0]}
                  value={detailsUser.user_name}
                  onChange={(e) => {
                    setDetailsUser({
                      ...detailsUser,
                      user_name: e.target.value,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  // value={formik.values.user_name}
                />
              </ThemeProvider>

              {formik.touched.user_name && formik.errors.user_name ? (
                <div className=" ml-2 text-sm text-red-600">
                  {formik.errors.user_name}
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full flex sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2 pb-4">
            <div className="w-full flex flex-col">
              <div>
                <ThemeProvider theme={theme}>
                  <FormControl required fullWidth>
                    <InputLabel>User Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="user_role"
                      label="User Role"
                      defaultValue={
                        detailsUser.user_role === "user"
                          ? "Agent"
                          : detailsUser.user_role === "NHQ"
                          ? "Super User"
                          : detailsUser.user_role
                      }
                      onChange={(e) => {
                        setDetailsUser({
                          ...detailsUser,
                          user_role: e.target.value,
                        });
                      }}
                      onBlur={formik.handleBlur}
                      // value={userInput.user_role}
                    >
                      <MenuItem value={"Agent"}>Agent</MenuItem>
                      <MenuItem value={"manager"}>Manager</MenuItem>
                      <MenuItem value={"Cluster Manager"}>
                        Cluster Manager
                      </MenuItem>
                      <MenuItem value={"Super User"}>Super User</MenuItem>
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </div>
              <div>
                {formik.touched.user_role && formik.errors.user_role ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.user_role}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              {/* <Autocomplete
                value={detailsUser.store_number}
                options={store}
                getOptionLabel={(store) => {
                  let store_id = store.store_id;
                  return store_id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Your Store"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  // console.log(value.id);
                  setDetailsUser({
                    ...detailsUser,
                    store: value.id,
                    stores: [value.id],
                  });
                }}
              /> */}
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  type="text"  
                  label="Select Your Store"
                  // name="number"
                  variant="outlined"
                  // InputProps={
                  //   userData.data.user_role === "Super Admin" ||
                  //   userData.data.user_role === "NHQ"
                  //     ? { readOnly: false }
                  //     : { readOnly: true }
                  // }
                  disabled
                  // InputProps={{ readOnly: true }}
                  //   defaultValue={states[0]}
                  value={detailsUser.store_number}  
                  // onChange={(e) => {
                  //   setDetailsUser({
                  //     ...detailsUser,
                  //     number: e.target.value,
                  //   });
                  // }}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.number}
                />
              </ThemeProvider>
            </div>
          </div>
          <div className="w-full flex sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2 pb-4">
            <div className="w-full">
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  type="Number"
                  label="Contact Number"
                  name="number"
                  variant="outlined"
                  InputProps={
                    userData.data.user_role === "Super Admin" ||
                    userData.data.user_role === "NHQ"
                      ? { readOnly: false }
                      : { readOnly: true }
                  }
                  //   defaultValue={states[0]}
                  value={detailsUser.number}
                  onChange={(e) => {
                    setDetailsUser({
                      ...detailsUser,
                      number: e.target.value,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  // value={formik.values.number}
                />
              </ThemeProvider>

              {/* {formik.touched.number && formik.errors.number ? (
                <p className=" text-sm text-red-600">{formik.errors.number}</p>
              ) : null} */}
            </div>

            <div className="w-full">
            <ThemeProvider theme={theme}>
              {detailsUser.user_role === "manager" || detailsUser.user_role === "Cluster Manager" || detailsUser.user_role === "NHQ" ? (
                <TextField
                fullWidth
                type="email"
                label="Email ID"
                required
                name="email"
                variant="outlined"
                InputProps={
                  userData.data.user_role === "Super Admin" ||
                  userData.data.user_role === "NHQ"
                    ? { readOnly: false }
                    : { readOnly: true }
                }
                //   defaultValue={states[0]}
                value={detailsUser.email}
                  onChange={(e) => {
                    setDetailsUser({
                      ...detailsUser,
                      email: e.target.value,
                    });
                  }}
                // defaultValue={detailsUser.user_email}
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                //   value={formik.values.email}
              />
              ) : (
                <TextField
                fullWidth
                type="email"
                label="Email ID"
                name="email"
                variant="outlined"
                InputProps={
                  userData.data.user_role === "Super Admin" ||
                  userData.data.user_role === "NHQ"
                    ? { readOnly: false }
                    : { readOnly: true }
                }
                //   defaultValue={states[0]}
                value={detailsUser.email}
                  onChange={(e) => {
                    setDetailsUser({
                      ...detailsUser,
                      email: e.target.value,
                    });
                  }}
                // defaultValue={detailsUser.user_email}
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                //   value={formik.values.email}
              />
              )
              }
             
            {/* {formik.touched.email && formik.errors.email ? (
              <div className=" ml-2 text-sm text-red-600">
                {formik.errors.email}
              </div>
            ) : null}  */}
            </ThemeProvider>
            </div>

            <div className="w-full">
              <ThemeProvider theme={theme}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="Status"
                    label="Status"
                    onChange={(e) => {
                      setDetailsUser({
                        ...detailsUser,
                        status: e.target.value,
                      });
                    }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.user_role}
                    value={detailsUser.status}
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </div>
          </div>


          <div className="w-full flex font-[roboto] justify-end gap-2">
            <button
              onClick={handleEditClose}
              className=" rounded-md shadow-md text-sm font-bold bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-20 h-8"
            >
              Cancel
            </button>
            {userData.data.user_role === "Super Admin" ||
            userData.data.user_role === "NHQ" ? (
              <button
                type="submit"
                onClick={handleSubmit}
                className=" rounded-md shadow-md text-sm font-bold bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-20 h-8"
              >
                Save
              </button>
              
            ) : (
              <></>
            )}
          </div>
          <Toaster/>
        </DialogContent>

        {/* <DialogContent className="flex flex-col space-y-4">
          <div className="w-full xl:h-[700px] flex flex-col space-y-2 md:space-y-0 xl:flex-row xl:space-x-7 overflow-hidden">
            <div className="w-full h-[300px] xl:w-5/12 xl:h-full xl:px-5 xl:py-2 flex flex-col justify-end bg-white shadow-md rounded-2xl">
              <form className="h-full" onSubmit={formik.handleSubmit} action="">
                {detailsUser && (
                  <div className="flex h-full justify-evenly flex-col space-y-7">
                    <div
                      id="rubikFont"
                      className="w-full flex justify-center text-2xl font-semibold text-gray-500"
                    >
                      <span>User Details</span>
                    </div>
                    <TextField
                      fullWidth
                      label="User Name"
                      defaultValue={detailsUser.user_name}
                      variant="standard"
                      name="user_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="User ID"
                      defaultValue={detailsUser.user_id}
                      variant="standard"
                      name="user_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      defaultValue={detailsUser.email}
                      variant="standard"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="Number"
                      defaultValue={detailsUser.number}
                      variant="standard"
                      name="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="flex flex-row space-x-4">
                      <FormControl className="w-full">
                        <InputLabel>User Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          name="user_role"
                          label={detailsUser.user_role}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={"user"}>User</MenuItem>
                          <MenuItem value={"manager"}>Manager</MenuItem>
                          <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl className="w-full">
                        <InputLabel>Store Name</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          name="store"
                          label="Store Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {store &&
                            store.map((val) => (
                              <MenuItem value={val.id}>{val.name}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className=" flex justify-end">
                      <div className="flex flex-row space-x-2">
                        <button
                          // open={close}
                          onClick={handleEditClose}
                          className="bg-red-600 rounded-md shadow-md text-sm font-bold active:bg-red-500 hover:bg-red-800 text-white w-20 h-8"
                        >
                          Cancel
                        </button>
                        <div>
                          <button
                            type="submit"
                            className="bg-emerald-500 rounded-md shadow-md text-sm font-bold active:bg-emerald-500 hover:bg-emerald-800 text-white w-20 h-8"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </DialogContent> */}
      </Dialog>
    </>
  );
}