import { React, useState, useEffect } from "react";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { HiSpeakerphone } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { DialogContent, Dialog } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  // CREATEBROADCAST,
  GET_STORES_REGION,
} from "../../../Authservice/api";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import toast, { Toaster } from "react-hot-toast";
// import AWS from 'aws-sdk';
// import { Socket } from "socket.io-client";
// const ENDPOINT = 'wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test';

export default function SendBroadcast() {
  // eslint-disable-next-line
  const [
    // messagess,
    setMessagess,
  ] = useState([]);
  const [readySocket, setReadySocket] = useState(null);
  const socket = new WebSocket(
    "wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test"
  );
  const userss = localStorage.getItem("users");
  const userDatas = JSON.parse(userss);
  // let storesData;
  let user_roles;

  if (userDatas) {
    // storesData = userDatas.data.stores;
    user_roles = userDatas.data.user_role;
  }

  useEffect(() => {
    socket.onopen = () => {
      // console.log('WebSocket connected');
      const userName = user_roles;
      const payload = {
        action: "setName",
        userName,
      };
      setReadySocket(socket);

      socket.send(JSON.stringify(payload));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // console.log('WebSocket message received:', data);

      switch (data.type) {
        case "newDocument":
          setMessagess((prevMessages) => [...prevMessages, data.document]);
          break;

        default:
        // console.log('Unknown message type:', data.type);
      }
    };

    socket.onclose = () => {
      // console.log('WebSocket disconnected');
    };

    return () => {
      socket.close();
      // console.log('WebSocket disconnected');
    };
    // eslint-disable-next-line
  }, []);

  // console.log("messages", messagess)

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [
    // regions,
    setRegions,
  ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //!FOR THE SELECT FIELDS FROM MUI WITH CHECKMARKS STARTS
  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };
  useEffect(() => {
    async function getRegion() {
      try {
        const data = await GET_STORES_REGION();
        if (data) {
          setRegions(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getRegion();
    // eslint-disable-next-line
  }, []);

  // const regions = ["All", "Mumbai", "Navi Mumbai", "Pune"];

  const [
    // regionsName,
    setRegionsName,
  ] = useState([]);
  // eslint-disable-next-line
  const handleChangeRegion = (event) => {
    const {
      target: { value },
    } = event;
    setRegionsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const recipients = ["All", "Cluster Manager", "manager", "user"];

  const [recipientsRole, setRecipientsRole] = useState([]);
  // eslint-disable-next-line
  const handleChangeRecipients = (event) => {
    const {
      target: { value },
    } = event;
    setRecipientsRole(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  //!FOR THE SELECT FIELDS FROM MUI WITH CHECKMARKS ENDS

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  // const [broadcast, setBroadcast] = useState([]);

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);
  const userID = userData.data._id;
  const user_role_data = userData.data.user_role;

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [statesAndCitiesMap, setStatesAndCitiesMap] = useState({});

  console.log("start");
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(
          "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev/activecom/get_unique_location"
        );
        const data = await response.json();

        if (data) {
          const states = data.states;

          states.unshift("All");

          setStates(states);

          const citiesWithAllOption = data.cities;
          citiesWithAllOption.unshift("All");

          setCities(citiesWithAllOption);
          const statesAndCitiesMap = data.statesAndCitiesMap;
          setStatesAndCitiesMap(statesAndCitiesMap);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);
  console.log("state", states);

  const [statesName, setStatesName] = useState([]);
  const [citiesName, setCitiesName] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // console.log("readyState", socket)
  //   // if (!socket || socket.readyState !== WebSocket.OPEN) {
  //   //   // console.log('WebSocket is not open');
  //   //   toast.error(" Please refresh the page");
  //   //   return;
  //   // }
  //   const allData = {
  //     type: "NHQ",
  //     user: recipientsRole,
  //     state: statesName,
  //     city: citiesName,
  //     title: subject,
  //     body: body,
  //     userID: userID,
  //   };

  //   // const submitSocket = new WebSocket('wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test');
  //   // console.log("submitSocket", readySocket)

  //   const payload = {
  //     action: "sendMessage",
  //     allData,
  //   };

  //   readySocket.send(JSON.stringify(payload));

  //   // console.log("allData", allData)

  //   try {
  //     const data = await CREATEBROADCAST(allData);
  //     // console.log(data.data);
  //     if (data) {
  //       // window.location.reload();   setOpen(false);
  //       toast.success("Broadcast message created ");
  //       setTimeout(function () {
  //         setOpen(false);
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // setBroadcast(values =>({regionsName, recipientsRole, subject, body, }))
  //   // alert(inputs);
  //   // console.log("New Broadcast", broadcast)
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const allData = {
      type: "NHQ",
      user: recipientsRole,
      state: statesName,
      city: citiesName,
      title: subject,
      body: body,
      userID: userID,
    };

    console.log("all data", allData);

    const payload = {
      action: "sendMessage",
      allData,
    };

    readySocket.send(JSON.stringify(payload));

    try {
      const response = await fetch(
        "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev/notification/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(allData),
        }
      );

      if (response.ok) {
        // const data = await response.json();
        toast.success("Broadcast message created ");
        setTimeout(function () {
          setOpen(false);
        }, 1000);
      } else {
        console.error("Failed to create broadcast:", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSubmit();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [subject, readySocket]);

  const renderTags = (value) => {
    const maxTagsToShow = 1; // Maximum number of tags to show before truncating
    const additionalTagsCount = value.length - maxTagsToShow;

    const tagsToShow = value.slice(0, maxTagsToShow);
    const additionalTags = additionalTagsCount > 0 ? `...` : null;

    return (
      <p>
        {tagsToShow.join(", ")}
        {additionalTags && <>, {additionalTags}</>}
      </p>
    );
  };

  return (
    <>
      {user_role_data === "Super Admin" || user_role_data === "NHQ" ? (
        <button
          type="button"
          onClick={handleOpen}
          className="flex space-x-2 px-3 py-2.5 mt-1 xl:mt-0 bg-emerald-500 hover:bg-emerald-700 active:bg-emerald-600 shadow-2xl rounded-md text-white "
        >
          <span id="rubikFont" className="self-center drop-shadow-2xl">
            Broadcast
          </span>
          <HiOutlineSpeakerphone className="text-lg self-center drop-shadow-2xl" />
        </button>
      ) : (
        <></>
      )}

      {/* <button onClick={sendMessage}>Socket </button> */}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
            // backgroundColor: "#d1d5db",
            // boxShadow: "none",
          },
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          sx={{ padding: 0 }}
          className="overflow-y-auto scrollbar"
        >
          <div className="w-full h-full flex flex-col space-y-1">
            <div className="w-full h-12 bg-emerald-500 text-white items-center px-3 md:px-8 flex justify-between ">
              <div className="flex flex-row space-x-2">
                <span id="rubikFont" className="text-lg">
                  New Broadcast
                </span>
                <HiSpeakerphone className="text-xl self-center" />
              </div>
              <RxCross2
                onClick={handleClose}
                className="text-xl cursor-pointer"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col pb-3 space-y-3 px-3 md:px-8">
                <div className="flex flex-row py-2 border-b border-gray-400 hover:border-b-2 hover:border-black space-x-3 md:space-x-8">
                  <span id="rubikFont" className="self-center pt-1">
                    To
                  </span>

                  {/* Dropdown for Recipients */}
                  <Autocomplete
                    style={{ marginRight: "8%" }}
                    fullWidth
                    multiple
                    id="recipients-autocomplete"
                    options={recipients}
                    value={recipientsRole}
                    onChange={(event, newValue) => {
                      if (newValue.includes("All")) {
                        setRecipientsRole(recipients);
                        return;
                      }
                      setRecipientsRole(newValue);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={recipientsRole.indexOf(option) > -1}
                          color="primary"
                        />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Recipients"
                        variant="outlined"
                      />
                    )}
                    renderTags={renderTags}
                  />
                  {/* Dropdown for States */}
                  <Autocomplete
                    fullWidth
                    multiple
                    id="states-autocomplete"
                    options={states}
                    value={statesName}
                    onChange={(event, newValue) => {
                      if (newValue.includes("All")) {
                        setStatesName(states);
                        setAvailableCities(cities);
                        setCitiesName([]); // Clear the selected cities when the state changes
                        return;
                      }
                      setStatesName(newValue);
                      // Filter cities based on the selected state
                      const selectedCities = newValue.reduce((acc, state) => {
                        if (statesAndCitiesMap[state]) {
                          acc.push(...statesAndCitiesMap[state]);
                        }
                        return acc;
                      }, []);
                      selectedCities.unshift("All");
                      setAvailableCities(selectedCities);
                      setCitiesName([]); // Clear the selected cities when the state changes
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={statesName.indexOf(option) > -1}
                          color="primary"
                        />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="States"
                        variant="outlined"
                      />
                    )}
                    renderTags={renderTags}
                  />

                  {/* Dropdown for Cities  */}
                  <Autocomplete
                    fullWidth
                    multiple
                    id="cities-autocomplete"
                    options={availableCities}
                    value={citiesName}
                    onChange={(event, newValue) => {
                      if (newValue.includes("All")) {
                        setCitiesName(availableCities);
                        return;
                      }
                      setCitiesName(newValue);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={citiesName.indexOf(option) > -1}
                          color="primary"
                        />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cities"
                        variant="outlined"
                      />
                    )}
                    renderTags={renderTags}
                  />
                </div>
                <TextField
                  fullWidth
                  size="small"
                  id="standard-basic"
                  label="Subject"
                  variant="standard"
                  onChange={(e) => setSubject(e.target.value)}
                />
                <TextField
                  id="standard-multiline-static"
                  // label="Multiline"
                  multiline
                  rows={10}
                  // defaultValue="Default Value"
                  variant="standard"
                  onChange={(e) => setBody(e.target.value)}
                />

                <div className="w-full flex justify-end">
                  <button onSubmit={handleSubmit} class="sendButton">
                    <div class="svg-wrapper-1">
                      <div class="svg-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <span>Send</span>
                  </button>
                  <Toaster />
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
