import React, { useState, useEffect } from "react";
// import SideNav from "../others/sidenav";
import DashHeader from "./dashheader";
// import Card from "./card _v2";
import CardNew from "./card";
// import TrialCard from "./trialCard";
import Calender from "./calender";
import CalenderNew from "./calendarNew";
import BarChart from "./rankingChart";
import DoughnutChart from "./doughnutChart";
// import DoughnutChartV1 from "./doughnutChartV1";
import SideNav from "../others/sidenav";
import ProgressBar from "./progressBar";
import {
  //  GetALLStoreAPI,
  GetALLStoreAPI_ROLE,
  GetALLCaptureProgress,
  FULLNESSCAPTURECARD,
} from "../Authservice/api";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
// import Select from "react-select";
import axios from "axios";
import Logo from "../Authservice/images/neo_disha_final_logo.gif";
// import Loaders from "../others/loader";
import token from "../Authservice/authToken";
// import Tooltip from "@mui/material/Tooltip";
import MobileDashboardCalender from "../others/mobileDashboardCalender";
// import { BiSelectMultiple } from "react-icons/bi";
import NewLoader from "../others/loaderUi";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";

const performances = [{ value: "High to low" }, { value: "Low to high" }];

export default function DashboardAnalyse() {
  const lambUrl = "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev";

  const [parentdata, setParentdata] = useState("");

  const [
    storeIdValue,
    // setStoreIdValue
  ] = useState([]);
  const [fullnessCapture, setFullnessCapture] = useState(false);
  const [captureStore, setCaptureStore] = useState(false);
  const [fullness, setFullness] = useState(false);
  const [analysisData2, setAnalysisData2] = useState(false);
  const [performance, setPerformamce] = useState("Low to high");
  const [fullnessScore, setFullnessScore] = useState(false);
  // console.log("store ID value ", storeIdValue);

  // console.log("Selected Store Id", storeIdValue);

  //  get the user Details
  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  let stores;
  if (userData) {
    stores = userData.data.stores;
  }

  let storeIDs;

  if (storeIdValue[0] === undefined) {
    storeIDs = stores;
  } else {
    storeIDs = storeIdValue;
  }
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // Set the component to be mounted when the effect is run
    setIsMounted(true);

    // Return a cleanup function to set the component to unmounted
    return () => {
      setIsMounted(false);
    };
  }, []);
  // ------------------------

  useEffect(() => {
    if (isMounted) {
      async function fetchData() {
        const allData = {
          start_date: parentdata,
          Store_IDs: storeIDs,
          sort: performance,
        };
        const datas = {
          Store_IDs: storeIDs,
          selectedDate: parentdata,
        };
        const apiDatas = {
          date: parentdata,
          stores: storeIDs,
        };
        const allDatas = {
          Store_IDs: storeIDs,
          start_date: parentdata,
          end_date: parentdata,
        };
        try {
          const [
            analysisData3,
            fullnessScoreData,
            fullnessCaptureData,
            captureStoreData,
            fullnessData,
          ] = await Promise.all([
            axios.post(`${lambUrl}/getimagesanalysis`, allData, {
              headers: {
                Accept: "application/json",
                Authorization: await token(),
              },
            }),
            axios.post(`${lambUrl}/fullnesscore`, allData, {
              headers: {
                Accept: "application/json",
                Authorization: await token(),
              },
            }),
            FULLNESSCAPTURECARD(apiDatas),
            GetALLCaptureProgress(datas),
            axios.post(`${lambUrl}/getfullnessanalysis`, allDatas, {
              headers: {
                Accept: "application/json",
                Authorization: await token(),
              },
            }),
          ]);
          if (analysisData3) {
            setAnalysisData2(analysisData3.data);
          }
          if (fullnessScoreData) {
            setFullnessScore(fullnessScoreData.data);
          }

          if (fullnessCaptureData) {
            setFullnessCapture(fullnessCaptureData.data);
          }

          if (captureStoreData) {
            setCaptureStore(captureStoreData.data);
          }

          if (fullnessData) {
            setFullness(fullnessData);
          }
        } catch (error) {
          console.log("error");
        }
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [parentdata, performance]);

  // const [analysisData2, setAnalysisData2] = useState(false);
  // // const [loop, setLoop] = useState(false);
  // useEffect(() => {
  //   async function getDashboardAnalyses() {
  //     const allData = {
  //       Store_IDs: storeIDs,
  //       start_date: parentdata,
  //       end_date: parentdata,
  //     };
  //     // console.log("allData", allData)
  //     try{
  //            const res = await axios.post(`${lambUrl}/getimagesanalysis`, allData, {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: await token(),
  //         },
  //       });
  //       // console.log(res.data);

  //       setAnalysisData2(res.data);
  //       // setLoop(true);
  //     } catch (error) {
  //       console.log("Error Calling all Store API: ", error);
  //     }
  //   }
  //   getDashboardAnalyses();
  //   // eslint-disable-next-line
  // }, [parentdata, storeIdValue]);

  // const [fullnessScore, setFullnessScore] = useState(false);
  // useEffect(() => {
  //   async function getDashboardAnalyses2() {
  //     let allData = {
  //       start_date: parentdata,
  //       Store_IDs: storeIDs,
  //     };
  //     try {
  //       const res = await axios.post(`${lambUrl}/fullnesscore`, allData, {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: await token(),
  //         },
  //       });

  //       setFullnessScore(res.data);
  //       // console.log(res.data);
  //     } catch (error) {
  //       console.log("Error Calling all Store API: ", error);
  //     }
  //   }
  //   getDashboardAnalyses2();
  //   // eslint-disable-next-line
  // }, [parentdata]);

  // const apiData = {
  //   date: parentdata,
  //   stores: storeIDs,
  // };
  // useEffect(() => {
  //   async function getFullnessCapture() {
  //     try {
  //       const data = await FULLNESSCAPTURECARD(apiData);
  //       // console.log(data.data);
  //       setFullnessCapture(data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getFullnessCapture();
  //   // eslint-disable-next-line
  // }, [parentdata]);

  // useEffect(() => {
  //   async function getStores() {
  //     const datas = {
  //       Store_IDs: storeIDs,
  //       selectedDate: parentdata,
  //     };
  //     try {
  //       const data = await GetALLCaptureProgress(datas);
  //       if (data) {
  //         setCaptureStore(data.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getStores();
  //   // eslint-disable-next-line
  // }, [parentdata]);

  // console.log("analysis data 2", fullnessCapture)

  // useEffect(() => {
  //   async function getDashboardAnalyses() {
  //     const allData = {
  //       Store_IDs: storeIdValue,
  //       start_date: parentdata,
  //       end_date: parentdata,
  //     };
  //     try {
  //       const res = await axios.post(
  //         `${url}/data/analysis/getImagesAnalysis`,
  //         allData,
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: await token(),
  //           },
  //         }
  //       );
  //       // console.log(res.data);

  //       setAnalysisData(res.data);
  //       setLoop(true);
  //     } catch (error) {
  //       console.log("Error Calling all Store API: ", error);
  //     }
  //   }
  //   getDashboardAnalyses();
  // }, [parentdata, storeIdValue]);

  // Store Id Integration
  const [store, setStore] = useState([]);
  const [
    store_default,
    // setStore_default
  ] = useState({
    _id: undefined,
    name: "All Stores",
  });
  useEffect(() => {
    async function getStores() {
      try {
        const data = await GetALLStoreAPI_ROLE(stores);
        if (data) {
          data.data.unshift(store_default);
        }
        // console.log(data.data);
        setStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
    // eslint-disable-next-line
  }, []);
  // console.log("store", store);
  // useEffect(() => {
  //   setStore(prevStore => {
  //     const storeArray = [store_default, ...prevStore]; // Adjust the order of concatenation
  //     return storeArray;
  //   });
  // }, [store_default]);
  // Current Day Date Year
  const current = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthIndex = new Date().getMonth();
  let monthName = monthNames[monthIndex];
  // eslint-disable-next-line
  const date = `${current.getDate()}-${monthName}-${current.getFullYear()}`;
  const getTodayTime = new Date().getHours();
  // const getTime = new Date().getTime()

  // Greeting to user in Dashboard page
  let greetingUser = "";
  if (getTodayTime < 12) {
    // console.log("Good Moring")
    greetingUser = "Good morning";
  } else if (getTodayTime < 17) {
    greetingUser = "Good afternoon";
  } else {
    greetingUser = "Good evening";
  }

  //  get the user Details

  // console.log("date is here",getTodayTime)
  //   get the cookies from localStorage
  // const [accessToken, setAccessToken] = useState(() => {
  //   const savedItem = localStorage.getItem("token");

  // });

  // console.log("accessToken for login user", savedItem);

  // Get percentage of image fulness

  // useEffect(() => {
  //   async function getFullnessPercentage() {
  //     const allData = {
  //       Store_IDs: storeIDs,
  //       start_date: parentdata,
  //       end_date: parentdata,
  //     };
  //     try {
  //       const res = await axios.post(
  //         `${lambUrl}/getfullnessanalysis`,
  //         allData,
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: await token(),
  //           },
  //         }
  //       );
  //       // console.log(res.data);

  //       setFullness(res);
  //     } catch (error) {
  //       console.log("Error Calling all Store API: ", error);
  //     }
  //   }
  //   getFullnessPercentage();
  //   // eslint-disable-next-line
  // }, [parentdata, storeIdValue]);

  //  Clear  the text from the select store

  // const isClearable = () => {
  //   setStoreIdValue("");

  // };
  // const clearField = undefined;
  // console.log("storeIdValue", storeIdValue)
  // console.log("Sort", performance);
  return (
    <>
      <div className="w-full  md:h-screen h-full pb-6 md:pb-0 flex  bg-gray-100 ">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen"></div>

        <div className="md:w-5/6 w-full h-full  ">
          <div className="w-full ">
            <DashHeader store={store} />

            <span
              // onClick={DashboardAnalyses}
              className="font-sans  pl-8 pr-8 font-bold text-gray-600 font-sm text-md	 		"
            >
              {greetingUser}, {userData.data.user_name}
            </span>
          </div>

          {analysisData2 &&
          captureStore &&
          fullnessCapture &&
          fullnessScore === false ? (
            <div className=" grid">
              <div className="space-y-4">
                <img
                  className="m-auto w-1/2 mt-36  "
                  src={Logo}
                  alt=" not found "
                />
                <div className="w-full flex justify-center">
                  <NewLoader />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full xl:h-5/6 h-full 2xl:mt-5 flex flex-row space-y-6 xl:space-y-0 xl:pl-4">
              {/*space-y-6 pl-4*/}
              <div className="md:w-3/4 w-full 2xl:flex 2xl:flex-col 2xl:space-y-3 pl-2 md:pl-0 pr-2 md:pr-4 pt-1 md:pt-0">
                {/*pr-4 pt-4 md:pt-0 2xl:pt-4*/}
                {/* // <div className="w-full md:h-5/6 h-full  flex space-y-6 pl-4   ">
            //   <div className="md:w-3/4 w-full pr-4 pt-4"> */}
                <div className="w-full md:h-2/6 overflow-x-scroll overflow-y-hidden xl:overflow-hidden flex justify-center items-center ">
                  <CardNew
                    data={analysisData2}
                    date={parentdata}
                    fullneCapture={fullnessCapture}
                    captureStore={captureStore}
                    store={store}
                  />
                </div>
                <div className="md:hidden flex flex-col space-y-2 py-2 ">
                  <div className="">
                    {/* {store && (
                      <div className="w-full cursor-pointer flex flex-row justify-between">
                        <Select
                          className="w-full px-2"
                          placeholder="All store"
                          onChange={(e) => setStoreIdValue([e.id])}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          options={Object.values(store).map((val) => val)}
                        />
                      </div>
                    )} */}
                  </div>

                  <div className="flex px-2 justify-between items-center">
                    <span className="font-medium text-lg">{parentdata}</span>
                    <MobileDashboardCalender>
                      <Calender setParentdata={setParentdata} />
                    </MobileDashboardCalender>
                  </div>
                </div>
                <div className="w-full md:h-4/6 flex md:flex-row flex-col pl-2 pr-2 md:p-2  md:space-x-6  md:space-y-0 space-y-5   ">
                  <div className="md:w-1/2 relative w-full flex p-4 bg-white rounded-lg shadow-md justify-center ">
                    <BarChart
                      store={store}
                      date={parentdata}
                      data={analysisData2}
                      fullnessScore={fullnessScore}
                      performance={performance}
                    />
                    <div className="absolute flex space-x-3 right-2 top-2">
                      <span className="self-center text-sm font-semibold tracking-tighter">
                        Least-full 10 stores
                      </span>
                      <FormControl style={{ width: 175 }} size="small">
                        <InputLabel color="success">Select Order</InputLabel>
                        <Select
                          size="small"
                          color="success"
                          label="Select Order"
                          name="performance"
                          value={performance}
                          onChange={(e) => {
                            setPerformamce(e.target.value);
                          }}
                          sx={{
                            backgroundColor: "white",
                            // color: "white",
                          }}
                        >
                          {performances.map((option, index) => (
                            <MenuItem
                              value={option.value}
                              key={index}
                              // disabled={option.disabled}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="md:w-1/2 w-full flex p-2  shadow-md  rounded-lg bg-white justify-center items-center">
                    {/* <DoughnutChart data={fullness} /> */}
                    <DoughnutChart data={fullness} />
                  </div>
                  <div className="md:hidden">
                    {captureStore && (
                      <div className="w-full">
                        <ProgressBar
                          date={parentdata}
                          captureStore={captureStore}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/4 h-full overflow-y-auto scrollbar pt-2 mr-8 md:block hidden">
                {/* Calender  */}
                <div className="flex flex-col w-full pb-2 h-full xl:space-y-3.5 2xl:space-y-6">
                  {/* <div className="w-full h-3/6">
                      <Calender setParentdata={setParentdata} />
                    </div> */}
                  <div
                    id="rubikFont"
                    className="flex justify-center text-xl font-bold text-slate-500"
                  >
                    SELECT DATE
                  </div>
                  <div className="w-full">
                    <CalenderNew setParentdata={setParentdata} />
                  </div>
                  {/* {store ? (
                    <div className="w-full border border-black cursor-pointer flex flex-row justify-between">
                      <Select
                        className="w-full"
                        placeholder="All store"
                        onChange={(e) => setStoreIdValue([e.id])}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        options={Object.values(store).map((val) => val)}
                      />
                    </div>
                  ) : (
                    <></>
                  )} */}
                  {captureStore && (
                    <div className="w-full h-full overflow-y-auto flex-grow">
                      <ProgressBar
                        date={parentdata}
                        captureStore={captureStore}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
