//!SIGN IN PAGE FOR LAPTOPS & DESKTOPS
import React, { useState, useEffect } from "react";
import { COUNTRYCODE } from "../others/countryCodeList";
import { useNavigate } from "react-router-dom";
import { DialogContent, Dialog } from "@mui/material";
import { authentication } from "../firebaseAuth/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import Logo from "../Authservice/images/reliance-trends-logo-png.png";
import DISHA_ART from "../Authservice/images/DISHA_Art_jpg.jpg";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

export default function NewSignIn() {
  const [countryCode, setCountryCode] = useState("+91");
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(countryCode);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  const getPhoneNumber = countryCode + phoneNumber;

  const reCaptchVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
        "expired-callback": () => {},
      },
      authentication
    );
  };

  const [verifyData, setVerifyData] = useState(false);
  let phoneNUmber2 = false;
  if (phoneNumber.length === 10) {
    phoneNUmber2 = phoneNumber;
  }

  const lambUrl = "https://ulmzftvnli.execute-api.ap-south-1.amazonaws.com/dev";
  // const lambUrl = "https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev";

  useEffect(() => {
    async function getveriFiedUser() {
      try {
        const res = await axios.get(
          `${lambUrl}/user/verify_user_creds?numbers=${phoneNUmber2}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setVerifyData(res.data);
      } catch (error) {
        console.log("Error Calling API: ", error);
      }
    }
    getveriFiedUser();
  }, [phoneNUmber2]);

  localStorage.setItem("users", JSON.stringify(verifyData));

  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const onSubmit = async () => {
    if (
      verifyData.data.user_role === "user" ||
      verifyData.data.status === "Inactive"
    ) {
      toast.error("You are not authorized to access");
    } else {
      reCaptchVerifier();
      let appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(authentication, getPhoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          if (confirmationResult) {
            setOpen(true);
            setTimer(30);
            toast.success("OTP sent successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePhoneNumberKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const navigateTo = useNavigate();
  const [otp, setOtp] = useState();
  const [accessToken, setAccessToken] = useState("");

  const otpVerification = async () => {
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
        setAccessToken(user.accessToken);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOtpKeyPress = (event) => {
    if (event.key === "Enter") {
      otpVerification();
    }
  };

  const location = useLocation();
  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(accessToken));
  }, [accessToken]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedData = searchParams.get("data");
    localStorage.clear();
    if (encodedData) {
      localStorage.clear();
      // console.log("encoded data");
      const decodedData = decodeURIComponent(encodedData);
      console.log("encoded data", typeof decodedData);
      // setVerifyData(JSON.parse(decodedData));
      const verifiedData = JSON.parse(decodedData);
      const mallName = verifiedData.mallName; // Access the property directly from the object
      localStorage.setItem("redirectedStore", mallName);
      localStorage.setItem("token", decodedData.accessToken);
      console.log("verifiedData", typeof verifiedData);
      if (
        verifiedData.data.user_role === "user" ||
        verifiedData.data.status === "Inactive"
      ) {
        toast.error("You are not authorized to access");
      } else {
        localStorage.setItem("users", JSON.stringify(verifiedData));
        setAccessToken(verifiedData.data.accessToken);
        localStorage.setItem("users", JSON.stringify(verifiedData));
        navigateTo("/analysis");
      }
    }
    // eslint-disable-next-line
  }, []);

  if (accessToken) {
    const searchParams = new URLSearchParams(location.search);
    const redirectParam = searchParams.get("redirect");
    if (redirectParam === "analysis") {
      navigateTo("/analysis");
    } else {
      navigateTo("/dashboard");
    }
  }

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }

    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(authentication, getPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP resent successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => setOpen(false);

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <div className="hidden md:block">
        <div className="w-full h-screen flex flex-row items-center bg-slate-200 md:justify-center">
          <div className="w-[800px] h-[481px] self-center flex flex-row rounded-2xl bg-white">
            <div
              id="backgroundColor"
              className="w-5/12 relative h-full rounded-l-2xl flex flex-col justify-end"
            >
              <div className="absolute tracking-wider font-medium text-white text-3xl top-12 w-full flex flex-col space-y-1 text-center">
                <span className="font-[rubik] font-semibold drop-shadow-2xl">
                  NeoDISHA
                </span>
                <span className="font-[roboto] text-lg drop-shadow-2xl">
                  Digital Shelf Analysis
                </span>
              </div>
              <img
                className="w-full rounded-bl-2xl"
                src={DISHA_ART}
                alt="DIShA Art"
              />
            </div>
            <div className="w-7/12 h-full flex flex-col">
              <div
                style={{ width: "100%", height: "48%" }}
                className="flex flex-col  pl-10 pt-7 pr-10"
              >
                <div
                  style={{ width: "100%", height: "40%" }}
                  className="flex flex-col justify-evenly"
                >
                  <div
                    id="rubikFont"
                    className="font-bold text-2xl text-gray-700"
                  >
                    Sign in
                  </div>
                  <div id="rubikFont" className="font-semibold text-gray-600">
                    to access your account
                  </div>
                </div>
                <div
                  style={{ width: "100%", height: "60%" }}
                  className="flex flex-col justify-evenly"
                >
                  <div id="rubikFont" className="flex flex-row space-x-2">
                    <select
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      className="px-2 py-2 transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-emerald-300"
                    >
                      {COUNTRYCODE.map((e) => (
                        <option value={e.dial_code}>{e.dial_code}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      maxLength={10}
                      label="Number"
                      name="number"
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      onKeyDown={handlePhoneNumberKeyPress}
                      placeholder="Mobile number"
                      autoFocus
                      className="px-4 py-2 w-full transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-emerald-300"
                    />
                  </div>
                  <div>
                    {verifyData.status === 201 ? (
                      <button
                        // onClick={loginAction}
                        type="submit"
                        className="w-full px-4 py-2 cursor-not-allowed text-lg font-semibold text-white transition-colors duration-300 bg-emerald-300 rounded-md shadow focus:outline-none focus:ring-emerald-200 focus:ring-4"
                      >
                        Verify user
                      </button>
                    ) : (
                      <button
                        onClick={onSubmit}
                        // onClick={loginAction}
                        type="submit"
                        className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-emerald-500 rounded-md shadow hover:bg-emerald-600 active:bg-emerald-300 focus:outline-none focus:ring-blue-200 focus:ring-4"
                      >
                        Send OTP
                      </button>
                    )}
                    <Toaster />
                    {/* <ToastContainer /> */}

                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={open}
                    >
                      <DialogContent className=" relative">
                        <div
                          onClick={handleClose}
                          className="absolute right-2 top-2 cursor-pointer "
                        >
                          <RxCross2 className="text-2xl text-gray-400" />
                        </div>
                        <div className="w-full grid space-y-4 ">
                          <div
                            id="rubikFont"
                            className="flex flex-col space-y-3"
                          >
                            <span className="text-lg font-bold">
                              Please Enter OTP
                            </span>
                            <div className="flex flex-row space-x-2">
                              <div>
                                <span>OTP sent to </span>
                                <span>{phoneNumber}</span>
                              </div>
                              <button
                                type="button"
                                onClick={refreshPage}
                                className="text-emerald-600 hover:text-emerald-400"
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-col space-y-4">
                            <input
                              id="rubikFont"
                              label="OTP"
                              type="text"
                              maxLength={6}
                              minLength={6}
                              name="otp"
                              onChange={(event) => setOtp(event.target.value)}
                              onKeyDown={handleOtpKeyPress}
                              className="px-4 py-2 transition duration-300 border border-gray-300 rounded bg-gray-100 focus:border-transparent focus:outline-none focus:ring-4 focus:ring-emerald-200"
                              placeholder="Enter 6 digit OTP"
                            />
                            <div className="flex flex-row justify-evenly">
                              {timer === 0 ? (
                                <>
                                  <button
                                    // onClick={otpVerification}
                                    onClick={resetTimer}
                                    className="w-5/12 hidden md:block px-4 py-2 text-lg font-semibold text-emerald-600 transition-colors duration-300 border-2 border-emerald-500 rounded-md shadow hover:bg-gray-200 active:bg-emerald-200 active:text-white focus:outline-none focus:ring-emerald-200 focus:ring-4"
                                  >
                                    Resend OTP
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    // onClick={otpVerification}
                                    className="w-5/12 hidden md:block px-4 py-2 text-lg font-semibold text-gray-400 transition-colors duration-300 border-2 border-gray-400 rounded-md shadow"
                                  >
                                    Resend OTP in {timer}
                                  </button>
                                </>
                              )}
                              <button
                                onClick={otpVerification}
                                className="w-5/12 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-emerald-500 rounded-md shadow hover:bg-emerald-600 active:bg-emerald-400 focus:outline-none focus:ring-emerald-200 focus:ring-4"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </div>
              <div className="w-10/12 self-center border"></div>
              <div
                style={{ width: "100%", height: "44%" }}
                className="flex flex-col pl-10 pt-7 pr-10 pb-5"
              >
                <div className="w-full mt-3 h-1/2 flex flex-row bg-cover bg-center justify-center">
                  <img
                    className="w-9/12 h-4/6 scale-[3.4] scale-x-[0.6] self-center"
                    src={Logo}
                    alt="Neo-DiShA Logo "
                  />
                </div>
                <p
                  id="rubikFont"
                  className="w-full pt-8 h-1/2 flex flex-col justify-center text-gray-600 text-center font-medium text-base"
                >
                  Image analysis platform for shelf-fullness monitoring and
                  smarter retail execution
                </p>
              </div>
              <div className="w-11/12 m-auto text-xs text-gray-600 ">
                Version:1.1.2
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
